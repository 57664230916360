import { render, staticRenderFns } from "./SecondMain.vue?vue&type=template&id=4c310eb3&scoped=true&"
import script from "./SecondMain.vue?vue&type=script&lang=js&"
export * from "./SecondMain.vue?vue&type=script&lang=js&"
import style0 from "./SecondMain.vue?vue&type=style&index=0&id=4c310eb3&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c310eb3",
  null
  
)

export default component.exports